exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-introduce-tsx": () => import("./../../../src/pages/introduce.tsx" /* webpackChunkName: "component---src-pages-introduce-tsx" */),
  "component---src-pages-member-[order]-tsx": () => import("./../../../src/pages/member/[order].tsx" /* webpackChunkName: "component---src-pages-member-[order]-tsx" */),
  "component---src-pages-news-[id]-tsx": () => import("./../../../src/pages/news/[id].tsx" /* webpackChunkName: "component---src-pages-news-[id]-tsx" */),
  "component---src-pages-news-tsx": () => import("./../../../src/pages/news.tsx" /* webpackChunkName: "component---src-pages-news-tsx" */),
  "component---src-pages-policy-markdown-remark-frontmatter-slug-tsx": () => import("./../../../src/pages/policy/{markdownRemark.frontmatter__slug}.tsx" /* webpackChunkName: "component---src-pages-policy-markdown-remark-frontmatter-slug-tsx" */),
  "component---src-pages-test-tsx": () => import("./../../../src/pages/test.tsx" /* webpackChunkName: "component---src-pages-test-tsx" */),
  "component---src-pages-work-[id]-tsx": () => import("./../../../src/pages/work/[id].tsx" /* webpackChunkName: "component---src-pages-work-[id]-tsx" */),
  "component---src-templates-members-tsx": () => import("./../../../src/templates/members.tsx" /* webpackChunkName: "component---src-templates-members-tsx" */),
  "component---src-templates-work-tsx": () => import("./../../../src/templates/work.tsx" /* webpackChunkName: "component---src-templates-work-tsx" */)
}

